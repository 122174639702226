<template>
	<div>
		<div class="indexUser">
			<div>
				<!-- header  点击 -->
				<headers :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div class="wrap bgw content">
					<div v-if="type==0">
						<div class="titles">我的问诊</div>
						<div class="box" v-for="(v, i) in inquiryList" :key="i">
							<div class="list">
								<img v-if="v.isDefaultImg || v.avatar == null || v.avatar == ''" src="@/assets/image/Furtherconsultation_Defaulthead@2x.png"
								 class="fl" />
								<img v-else :src="v.avatar" @error="v.isDefaultImg = true" class="fl" />
								<div class="fl txt">
									<p class="one">
										<span class="c1">{{ v.doctor_realname }}</span>
										<span>{{ v.cert_type == 1 ? "医生" : (v.cert_type == 2 ? "专家" : "药师")}}</span>
										<span>{{ v.doctor_info.jigou_name }}</span>
										<span>{{v.start_time}}</span>
									</p>
									<p class="one">科别： {{ v.category_name }}</p>
									<p class="one">临床诊断：{{ v.patient_info.diagnosis == '' ? "无" : v.patient_info.diagnosis }}</p>
									<p class="c1" @click="seeDetails(v)">查看详情</p>
								</div>
								<div class="fr">
									<span v-if="v.is_comment == 0" @click="GoComment(v)">评论</span>
									<!-- <span @click="sendFurther(v)">复诊</span> -->
								</div>
							</div>
						</div>
						<div class="page" v-if="showPage">
							<el-pagination background layout="prev, pager, next" :total="total" @current-change="currentChangePage"
							 :page-size="15"></el-pagination>
						</div>
					</div>

					<!-- 评价 -->
					<div v-if="type == 1">
						<div class="titles">问诊评价</div>
						<div class="assess">
							<div class="fl">
								<img v-if="commentInfo.isDefaultImg || commentInfo.avatar == null" src="@/assets/image/Furtherconsultation_Defaulthead@2x.png"
								 class="fl" />
								<img v-else :src="commentInfo.avatar" @error="commentInfo.isDefaultImg = true" class="fl" />
								<p>
									<span class="c1">{{ commentInfo.doctor_realname }}</span>
									{{ commentInfo.cert_type == 1 ? "医生" : (commentInfo.cert_type == 2 ? "专家" : "药师")}}
								</p>
								<p>
									{{ commentInfo.doctor_info.jigou_name }}
									<span></span>
								</p>
							</div>
							<div class="fr">
								<textarea placeholder="请留下您宝贵的意见" v-model="commentCont"></textarea>
								<p @click="sendComment">评论</p>
							</div>
						</div>
					</div>
					<!-- 问诊 详情 -->
					<div v-if="type == 2" class="wenzhen_details">
						<div class="headerTop">
							<div class="titles">问诊详情</div>
							<div class="list">
								<img v-if="commentInfo.isDefaultImg || commentInfo.avatar == null" src="@/assets/image/Furtherconsultation_Defaulthead@2x.png"
								 class="fl" />
								<img v-else :src="commentInfo.avatar" @error="v.isDefaultImg = true" class="fl" />
								<div class="fl txt">
									<p class="one">
										<span class="c1">{{ commentInfo.doctor_realname }}</span>
										<span>{{ commentInfo.cert_type == 1 ? "医生" : (commentInfo.cert_type == 2 ? "专家" : "药师")}}</span>
										<span>{{ commentInfo.doctor_info.jigou_name }}</span>
										<!-- <span>耳鼻喉科</span> -->
									</p>
									<p class="one">科别： {{ commentInfo.category_name }}</p>
									<p class="one">临床诊断：{{ commentInfo.patient_info.diagnosis == '' ? "无" : commentInfo.patient_info.diagnosis }}</p>
								</div>
								<div class="fr" v-if="commentInfo.is_comment == 0">
									<span @click="GoComment(commentInfo)">评论</span>
									<!--  <span @click="sendFurther(commentInfo)">复诊</span> -->
								</div>
							</div>
							<div class="txt">
								<div class="txts">
									<p class="ffl">姓名</p>
									<p class="ffr">{{ commentInfo.patient_info.realname ? commentInfo.patient_info.realname : '无' }}</p>
								</div>
								<div class="txts">
									<p class="ffl">年龄</p>
									<p class="ffr">{{ commentInfo.patient_info.age ? commentInfo.patient_info.age : "无" }}</p>
								</div>
								<div class="txts">
									<p class="ffl">性别</p>
									<p class="ffr">{{ commentInfo.patient_info.sex == 0 ? '保密' : (commentInfo.patient_info.sex == 1 ? '男' : '女') }}</p>
								</div>
								<div class="txts">
									<p class="ffl">体重</p>
									<p class="ffr">{{ commentInfo.patient_info.weight ? commentInfo.patient_info.weight : "无" }}</p>
								</div>
								<div class="txts">
									<p class="ffl">身高</p>
									<p class="ffr">{{ commentInfo.patient_info.height ? commentInfo.patient_info.height : '无' }}</p>
								</div>
								<div class="txts">
									<p class="ffl">手机号</p>
									<p class="ffr">{{ commentInfo.patient_info.mobile ? commentInfo.patient_info.mobile : '无' }}</p>
								</div>
								<div class="txts">
									<p class="ffl">过敏史</p>
									<p class="ffr">{{ commentInfo.patient_info.allergy ? commentInfo.patient_info.allergy : '无' }}</p>
								</div>
								<div class="txts">
									<p class="ffl">遗传史</p>
									<p class="ffr">{{ commentInfo.patient_info.genetic_disease ? commentInfo.patient_info.genetic_disease : '无' }}</p>
								</div>
								<div class="txts">
									<p class="ffl">疾病情况</p>
									<p class="ffr">{{ commentInfo.patient_info.diagnosis ? commentInfo.patient_info.diagnosis : '无' }}</p>
								</div>
								<div class="txts">
									<p class="ffl">上传检验报告</p>
									<p class="ffr"><img :src="commentInfo.patient_info.report_urls" alt=""></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from "../header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部
	import {
		usergetinfo
	} from "@/request/user";
	import {
		getInquiryList
	} from "@/request/user"; // 获取问诊列表
	import {
		addInquiryComment
	} from "@/request/user";
	import {
		sendFurtherInfo
	} from "@/request/user"; // 发送复诊信息
	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				type: 0,
				userList: [], // 个人信息
				inquiryList: [], // 问诊列表
				inquiryListPage: [],
				total: 0,
				showPage: false,
				commentInfo: {},
				commentCont: "",
				isDefaultImg: false,
				currentPage: 1
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			this.init();
			// this.state=this.$route.query.state
		},
		methods: {
			init() {
				//   个人信息
				usergetinfo({
					token: this.$tokens
				}).then(res => {
					this.userList = res.data;
				});
				this.getInquiryListInfo(1);
			},
			getInquiryListInfo(n) {
				getInquiryList({
					token: this.$tokens,
					page: n
				}).then(res => {
					if (res.code == 1) {
						this.showPage = res.data.last_page !== 1;
						this.total = res.data.total;
						res.data.data.forEach(item => (item.isDefaultImg = false));
						this.inquiryList = res.data.data;
					}
				});
			},
			currentChangePage(n) {
				// 当前页改变触发
				this.currentPage = n;
				this.getInquiryListInfo(n);
			},
			GoComment(v) {
				this.type = 1;
				this.commentInfo = { ...v
				};
			},
			sendComment() {
				addInquiryComment({
					token: this.$tokens,
					inquiry_doctor_id: this.commentInfo.id,
					content: this.commentCont
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							type: "success",
							message: res.msg
						});
						this.type = 0;
						this.commentCont = "";
						this.getInquiryListInfo(this.currentPage)
					}
				});
			},
			sendFurther(v) {
				sendFurtherInfo({
					token: this.$tokens,
					inquiry_doctor_id: v.id
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							type: "success",
							message: res.msg
						});
					}
				});
			},
			seeDetails(v) {
				console.log(v)
				this.type = 2;
				this.commentInfo = { ...v
				};
			}
		}
	};
</script>

<style lang="less" scoped>
	.content {
		min-height: 700px;
		border: 1px solid #e6e6e6;
	}

	.titles {
		height: 69px;
		line-height: 69px;
		text-indent: 36px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: rgba(78, 238, 200, 1);
		border-bottom: 1px solid #e6e6e6;
	}

	.c1 {
		color: #4eeec8;
		cursor: pointer;
	}

	.page {
		text-align: center;
		margin: 30px 0px;
	}

	//  主页
	.list {
		overflow: hidden;
		border-bottom: 1px solid #e6e6e6;
		height: 200px;
		padding-left: 36px;
		padding-right: 36px;
		padding-top: 30px;
		font-size: 18px;

		.fr {
			padding-top: 50px;

			span {
				margin-left: 32px;
				display: inline-block;
				width: 100px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border: 1px solid rgba(78, 238, 200, 1);
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(78, 238, 200, 1);
				cursor: pointer;
			}
		}

		img {
			width: 110px;
			height: 110px;
			margin-right: 33px;
		}

		.fl {
			p {
				line-height: 40px;
			}

			.one {
				span {
					margin-right: 30px;
				}
			}
		}

		.txt {
			margin-top: -10px;
		}
	}

	//  问诊 评价
	.assess {
		padding-top: 60px;
		padding-left: 130px;
		padding-right: 130px;
		overflow: hidden;

		.fl {
			img {
				width: 204px;
				height: 204px;
				margin-bottom: 36px;
				display: block;
			}

			p {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(49, 49, 49, 1);
				line-height: 40px;

				span {
					margin-right: 30px;
				}
			}

			.c1 {
				color: #4eeec8;
			}
		}

		.fr {
			textarea {
				width: 452px;
				height: 336px;
				box-sizing: border-box;
				font-size: 18px;
				padding: 20px;
				resize: none;
			}

			p {
				margin-top: 54px;
				width: 100px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: rgba(78, 238, 200, 1);
				border-radius: 8px;
				color: rgba(255, 255, 255, 1);
			}
		}
	}

	// .复诊
	.fuzhen {
		height: 194px;
		border-bottom: 1px solid #e5e5e5;
		padding-left: 73px;
		padding-right: 73px;
		padding-top: 47px;

		img {
			width: 100px;
			height: 100px;
			margin-right: 40px;
		}

		p {
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: rgba(51, 51, 51, 1);
			line-height: 40px;

			span {
				margin-right: 35px;
				display: inline-block;
			}
		}

		input {
			width: 30px;
			text-align: center;
			border-bottom: 1px solid#4D4D4D !important;
		}

		.fr {
			padding-top: 28px;

			button {
				width: 224px;
				height: 46px;
				font-size: 18px;
			}
		}
	}

	//  问诊详情
	.wenzhen_details {
		.txt {
			padding-bottom: 40px;
			padding-top: 10px;
		}

		.txts {
			font-size: 22px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: rgba(49, 49, 49, 1);
			line-height: 60px;
			padding-left: 88px;

			.ffl {
				width: 140px;
				display: inline-block;
			}

			.ffr {
				display: inline-block;
				color: rgba(168, 168, 168, 1);
			}

			img {
				width: 140px;
				vertical-align: middle;
			}
		}
	}
</style>
